* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
}

.nav-links {
  margin: 0;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url('../assets/backgroundbackground.jpg');
  background-repeat: repeat-x;
  background-size: cover, contain;
  color: white;
}

.nav-links li {
  list-style: none;
}

.nav-links li a {
  text-decoration: none;
  color: white;
  padding: 1rem;
  display: block;
}

.nav-links li:hover {
  background-color: #fb5a5c;
}

/* Habe die Input Boxen und die Button verändert */
input,
button {
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  background-color: #fff;
}

.wir {
  font-size: 20px;
  margin: 0 auto;
  width: 90%;
  max-width: 600px;
}

.logo {
  width: 100%;
}
.diy {
  width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#footer {
  text-align: center;
  position: fixed;
  left: 0;
  color: #000;
  width: 100%;
  bottom: 24px;
}
